// .form-wrapper {
//     width: 80vw !important;
//     height: 70vh;
// }

.actions {
    padding: 1.5rem 8px !important;
}

.error {
    color: #b71c1c;
    font-size: 13px;
    height: 20px;
}

.sub-section {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 48%;
}

.upload-section {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 1.0rem;
    }
}

.progress-section {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 0px;
}

.progress {
    margin-bottom: 30px;
}

.sub-section-label {
    margin-bottom: 0.5rem;
}

.drop-zone {
    display: flex;
    justify-content: space-between;
}

.MuiChip-root {
    margin-left: 0.5rem;
}

.dialog-content {
    overflow: hidden;
}