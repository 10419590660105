.card-list {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
}

.recognized-text {
    color: #1f8a66;
}

.error-text {
    color: #ff5252;
}