.menu {
    text-transform: uppercase;
    color: #fff !important;
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: none;
}

.add-project {
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 3px;
}

.dialog {
    height: 700px !important;
}