/* @import '../../assets/styles/variables.scss'; */

.header {
    height: 80px;
    width: 90%;
    background-image: linear-gradient(to bottom, #28b485, #55c57a);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
}

#system-name {
    color: white;
    font-size: 1.7rem;
    text-transform: uppercase;
}

.link-name {
    text-decoration: none;
}