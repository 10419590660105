.product-dialog {
    width: 900px !important;
    height: 700px !important;
}

.project-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
}

.filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-link {
    /* text-decoration: none; */
    color: #28b485;
}