.card-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  border-bottom: 2px solid #EEEEEE;
  align-items: center;
}


.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.app-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
}

h1 {
  color: #585858;
  font-size: 1.6rem;
}