$bg-color: linear-gradient(to top, #28b485, #55c57a) !important;
$gray: #999 !important;
$white: #fff !important;

.player, .audio-player {
    position: sticky;
    bottom: 0;
}

.rhap_container {
    background-image: $bg-color;
    color: $white;
}

.rhap_download-progress, .rhap_volume-bar {
    background: $gray;
}

.rhap_progress-filled, .rhap_progress-indicator, .rhap_volume-indicator {
    background-color: $white;
}

.rhap_main-controls-button, .rhap_time, .rhap_volume-button {
    color: $white;
}