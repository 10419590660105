.container {
  display: block;
  float: right;
  width: 25%;
  /* margin-bottom: 3rem; */
}

.search {
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 10px;
  width: 100%;
  line-height: 30px;
  }
  